<template>
  <div :key="id">
    <VTitle :title="title" class="route-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else>
      <VList
        hide-header
        :headers="headers"
        :rows="rows"
        :is-loading="isLoading"
        :expanded-indexes="expandedIndexes"
      >
        <!-- eslint-disable-next-line -->
        <template #item.image="{ item }">
          <VImage
            :name="item.name"
            :color="resourceColors[item.resource_type]"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.name="{ item }">
          <div class="font-medium">
            {{ item?.name }}
          </div>
        </template>

        <!-- eslint-disable-next-line -->
        <template #header.__ACTIONS="{ index }">
          <div class="flex justify-center">
            <VAction
              v-if="isExpandedAll"
              icon="collapse-all"
              :label="$t('app.collapse_all')"
              @click="onClickToggleExpandAll(rows.length)"
            />

            <VAction
              v-else
              :icon="'expand-all'"
              :label="$t('app.expand_all')"
              @click="onClickToggleExpandAll(rows.length)"
            />
          </div>
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.__ACTIONS="{ item, index }">
          <div class="flex justify-center">
            <VAction
              v-if="expandedIndexes.includes(index)"
              icon="collapse"
              :label="$t('app.collapse')"
              @click="onClickToggleExpand(index)"
            />

            <VAction
              v-else
              icon="expand"
              :label="$t('app.expand')"
              @click="onClickToggleExpand(index)"
            />
          </div>
        </template>

        <template #expanded-item="{ item }">
          <component :is="item.component" :tagged="item.results" />
        </template>
      </VList>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useToggleExpand from "@/composables/useToggleExpand";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import VImage from "@/components/VImage";
import VList from "@/components/tables/VList";
import VAction from "@/components/tables/VAction";
import TaggedAttestations from "./TaggedAttestations";
import TaggedCollections from "./TaggedCollections";
import TaggedEvaluations from "./TaggedEvaluations";
import TaggedLessons from "./TaggedLessons";
import TaggedTrainings from "./TaggedTrainings";
// Constants
import resourceColors from "@/constants/resourceColors";

export default {
  components: {
    VTitle,
    VList,
    VImage,
    VAction,
    VAlert,
    TaggedAttestations,
    TaggedTrainings,
    TaggedLessons,
    TaggedEvaluations,
    TaggedCollections
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.tagged_resources", 2),
        value: "name"
      },
      {
        text: t("app.results", 2),
        value: "result_count",
        headerClass: "justify-center",
        cellClass: "text-center"
      },
      {
        text: t("app.actions"),
        value: "__ACTIONS"
      }
    ];
    const TYPES = {
      ATTESTATIONS: "attestations",
      COLLECTIONS: "collections",
      EVALUATIONS: "evaluations",
      LESSONS: "lessons",
      TRAININGS: "trainings"
    };

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    const rows = computed(() => {
      const components = {
        [TYPES.ATTESTATIONS]: "TaggedAttestations",
        [TYPES.COLLECTIONS]: "TaggedCollections",
        [TYPES.EVALUATIONS]: "TaggedEvaluations",
        [TYPES.LESSONS]: "TaggedLessons",
        [TYPES.TRAININGS]: "TaggedTrainings"
      };

      return Object.keys(data.value)
        .filter(x => Object.values(TYPES).includes(x) && data.value[x].length)
        .map(x => ({
          resource_type: x,
          name: t(`app.${x}`, 2),
          result_count: data.value[x].length,
          results: data.value[x],
          component: components[x]
        }))
        .sort((a, b) => b.result_count - a.result_count);
    });
    const title = computed(() => {
      const title = t("app.tags");

      const name = data.value?.name;
      if (name) {
        return `${title} "${name}"`;
      }

      return title;
    });
    const documentTitle = computed(() => {
      const title = t("app.tags", 2);

      const name = data.value?.name;
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Composables
    const { request } = useRequest();
    const {
      expandedIndexes,
      isExpandedAll,
      onClickToggleExpand,
      onClickToggleExpandAll
    } = useToggleExpand();

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "platform.tags.read",
        pathParams: { id: props.id },
        showToaster: false
      });

      data.value = response?.payload?.data[0] ?? {};

      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(
      () => props.id,
      async () => {
        await getData();
      }
    );

    return {
      title,
      documentTitle,
      isLoading,
      headers,
      rows,
      resourceColors,
      // useToggleExpand
      expandedIndexes,
      isExpandedAll,
      onClickToggleExpand,
      onClickToggleExpandAll
    };
  }
};
</script>

<template>
  <VTable
    hide-header
    :rows="displayedRows"
    :headers="headers"
    @click:row="onClickRedirect"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :name="item.displayed_name" :color="item.colour" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_name }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          {{ item.displayed_details }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VTable>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VTable from "@/components/tables/VTable";

export default {
  components: {
    VTable,
    VChip,
    VImage
  },
  props: {
    tagged: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    // Composables
    const { getText } = useTexts();
    const { trimHTML } = useDisplay();
    const { getStatusColor } = useColor();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.name"),
        value: "name"
      },
      {
        text: t("app.version"),
        value: "version"
      },
      {
        text: t("app.type"),
        value: "type"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // Computed
    const isInstructor = computed(() => store.getters["user/isInstructor"]);
    const displayedRows = computed(() => {
      return props.tagged.map(row => ({
        ...row,
        rowClass: isInstructor.value ? "cursor-pointer" : "",
        displayed_name: getText(row.texts, "name"),
        displayed_department: row.department?.name || "",
        displayed_details: trimHTML(getText(row.texts, "details")),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });

    // Methods
    const onClickRedirect = item => {
      if (!isInstructor.value) {
        return;
      }

      router.push({
        name: `teaching-lessons-details`,
        params: { id: item?.id }
      });
    };

    return {
      onClickRedirect,
      headers,
      displayedRows,
      // useColor
      getStatusColor
    };
  }
};
</script>
